import * as React from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import "./Workflows.css";
import recipeImage from "../../assets/workflows/Recipe.png";
import taskImage from "../../assets/workflows/Task.png";
import growImage from "../../assets/workflows/Grow.png";
import ipmImage from "../../assets/workflows/Ipm.png";
import strainImage from "../../assets/workflows/Strain.png";
import AIImage from "../../assets/workflows/AI.png";
import useMediaQuery from "@mui/material/useMediaQuery";

const RecipeLabel = (selected: boolean) => (
  <Box className="tab-wrapper">
    <Typography
      className="tab-label"
      sx={{ color: selected ? "#046B34" : "#020202" }}
    >
      Recipes
    </Typography>
    <Typography
      className="tab-label-description"
      sx={{ color: selected ? "#020202" : "#020202" }}
    >
      Automate task scheduling, optimize for company needs (low cost or high yields) and A/B test different plans to continually optimize recipes.
    </Typography>
  </Box>
);

const AILabel = (selected: boolean) => (
  <Box className="tab-wrapper">
    <Typography
      className="tab-label"
      sx={{ color: selected ? "#046B34" : "#020202" }}
    >
      Embedded AI
    </Typography>
    <Typography
      className="tab-label-description"
      sx={{ color: selected ? "#020202" : "#020202" }}
    >
      Save time extracting cannabinoid + terpene values from test results and create work instructions from your SOPs in seconds!
    </Typography>
  </Box>
);

const TaskLabel = (selected: boolean) => (
  <Box className="tab-wrapper">
    <Typography
      className="tab-label"
      sx={{ color: selected ? "#046B34" : "#020202" }}
    >
      Task Management
    </Typography>
    <Typography
      className="tab-label-description"
      sx={{ color: selected ? "#020202" : "#020202" }}
    >
      Seamlessly schedule + organize team activity, track labor hours, and provide feedback + learnings.
    </Typography>
  </Box>
);

const GrowLabel = (selected: boolean) => (
  <Box className="tab-wrapper">
    <Typography
      className="tab-label"
      sx={{ color: selected ? "#046B34" : "#020202" }}
    >
      Grow Planner
    </Typography>
    <Typography
      className="tab-label-description"
      sx={{ color: selected ? "#020202" : "#020202" }}
    >
      Look ahead with monthly views, identify overlap between rooms, strains or labor and keep stakeholders informed.
    </Typography>
  </Box>
);

const IPMLabel = (selected: boolean) => (
  <Box className="tab-wrapper">
    <Typography
      className="tab-label"
      sx={{ color: selected ? "#046B34" : "#020202" }}
    >
      IPM Protocols
    </Typography>
    <Typography
      className="tab-label-description"
      sx={{ color: selected ? "#020202" : "#020202" }}
    >
      Address problems in real-time with notifications, identify trends and prevent future headaches.
    </Typography>
  </Box>
);

const StrainLabel = (selected: boolean) => (
  <Box className="tab-wrapper">
    <Typography
      className="tab-label"
      sx={{ color: selected ? "#046B34" : "#020202" }}
    >
      Strain Portfolio
    </Typography>
    <Typography
      className="tab-label-description"
      sx={{ color: selected ? "#020202" : "#020202" }}
    >
      View historical performance, plan in advance to meet demand, save new genetics, and see cannabinoid + terpene makeup change over time.
    </Typography>
  </Box>
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box className="tab-panel-wrapper">{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function Workflows() {
  const tablet = useMediaQuery("(max-width:1415px)");
  const desktop = useMediaQuery("(min-width:1416px)");
  const mobile = useMediaQuery("(max-width:768px)");
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box className="workflow-container">
      <Box className="workflow-title-wrapper">
        <Typography className="workflow-title">
        What’s inside Kiefa?
        </Typography>
      </Box>
      <Box
        className="workflow-content-wrapper"
        sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}
      >
        <Tabs
          orientation={tablet || mobile ? "horizontal" : "vertical"}
          variant="scrollable"
          allowScrollButtonsMobile
          scrollButtons={true}
          value={value}
          onChange={handleChange}
          aria-label="workflow-tabs"
          TabIndicatorProps={{
            style: {
              backgroundColor: "#046B34",
              borderRadius: "36.25px",
            },
          }}
          sx={{
            ".MuiTabs-indicator": {
              left: 0,
              height: tablet || mobile ? "10px" : "100px !important",
              width: tablet || mobile ? "100px !important" : "10px",
              marginTop: "31px",
              marginLeft: tablet || mobile ? "10px" : "0px",
            },
          }}
        >
          <Tab
            className="tab"
            label={RecipeLabel(value === 0)}
            {...a11yProps(0)}
            sx={{
              borderBottom: !desktop ? 10 : 0,
              borderLeft: desktop ? 10 : 0,
              borderColor: "divider",
            }}
          />
          <Tab
            className="tab"
            label={AILabel(value === 1)}
            {...a11yProps(1)}
            sx={{
              borderBottom: !desktop ? 10 : 0,
              borderLeft: desktop ? 10 : 0,
              borderColor: "divider",
            }}
          />
          <Tab
            className="tab"
            label={TaskLabel(value === 2)}
            {...a11yProps(2)}
            sx={{
              borderBottom: !desktop ? 10 : 0,
              borderLeft: desktop ? 10 : 0,
              borderColor: "divider",
            }}
          />
          <Tab
            className="tab"
            label={GrowLabel(value === 3)}
            {...a11yProps(3)}
            sx={{
              borderBottom: !desktop ? 10 : 0,
              borderLeft: desktop ? 10 : 0,
              borderColor: "divider",
            }}
          />
          <Tab
            className="tab"
            label={IPMLabel(value === 4)}
            {...a11yProps(4)}
            sx={{
              borderBottom: !desktop ? 10 : 0,
              borderLeft: desktop ? 10 : 0,
              borderColor: "divider",
            }}
          />
          <Tab
            className="tab"
            label={StrainLabel(value === 5)}
            {...a11yProps(5)}
            sx={{
              borderBottom: !desktop ? 10 : 0,
              borderLeft: desktop ? 10 : 0,
              borderColor: "divider",
            }}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Box className="tabPanel">
            <Box className="workflow-image" component="img" alt="Kiefa.io" src={recipeImage} />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box className="ai-image-wrapper">
            <Box className="tabPanel">
              <Box className="workflow-image" component="img" alt="Kiefa.io" src={AIImage} />
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box className="tabPanel">
            <Box className="workflow-image" component="img" alt="Kiefa.io" src={taskImage} />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Box className="tabPanel">
            <Box className="workflow-image" component="img" alt="Kiefa.io" src={growImage} />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Box className="tabPanel">
            <Box className="workflow-image" component="img" alt="Kiefa.io" src={ipmImage} />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Box className="tabPanel">
            <Box className="workflow-image" component="img" alt="Kiefa.io" src={strainImage} />
          </Box>
        </TabPanel>
      </Box>
    </Box>
  );
}
