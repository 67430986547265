import * as React from "react";
import { Box, Typography } from "@mui/material";
import "./Integrations.css";
import bioTrackImg from "../../assets/biotrack.png";
import metrcImage from "../../assets/metrc.png";
import confidentCannabis from "../../assets/confidentCannabis.png";

export default function Integrations() {
  return (
    <Box className="integrations-wrapper">
      <Box className="integrations-content-wrapper">
        <Box className="title-wrapper">
          <Typography className="integrations-title">
            Now Compatible with
          </Typography>
        </Box>
        <Box className="integrations-row">
          <Box component="img" alt="Kiefa.io" src={bioTrackImg} />
          <Box component="img" alt="Kiefa.io" src={metrcImage} />
          <Box component="img" alt="Kiefa.io" src={confidentCannabis} />
        </Box>
      </Box>
    </Box>
  );
}
