import ReactGA from 'react-ga4';

interface AnalyticsEvent {
  category: string;
  action: string;
  label: string;
}

export const trackEventToGA = (event: AnalyticsEvent): void => {
  const { category, action, label } = event;
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
};