import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import "./Hero.css";
import {
  Link
} from "react-router-dom";
import RightAccordian from "../../assets/right.png";
import { trackEventToGA } from "../../utils/utils";
import { useLocation } from 'react-router-dom'


interface HeroProps {
  title: string,
  message: string,
  showBookNow: boolean,
}

export default function Hero({ title, message, showBookNow }: HeroProps) {
  const location = useLocation();
  return (
    <Box className="hero-wrapper">
      <Box className="hero-text-wrapper">
        <Typography className="hero-title">{title}</Typography>
        <Typography className="hero-description">
          {message}
        </Typography>
        {showBookNow ? (
          <Box onClick={() => trackEventToGA({category: "Button Click", action: "book_demo_clicked", label: `Hero on ${location.pathname}` })}>
          <Link to="https://forms.gle/BjYG5Taw9GT5bcmaA" className="hero-book-now-link">
          <Typography className="book-demo-button">
            Book Demo
          </Typography>
          <Box
            className="checkIcon"
            component="img"
            alt="Kiefa.io"
            src={RightAccordian}
          />
        </Link>
          </Box>

        ) : null}
      </Box>
    </Box>
  );
}
