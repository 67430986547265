import React from "react";
import { Box, Typography, Card, CardContent } from "@mui/material";
import "./Advisors.css";
import mikey from "../../assets/MichaelToweyImage.png";
import grant from "../../assets/GrantVinkavichImage.png";

export default function Advisors() {
  return (
    <Box className="advisors-wrapper">
      <Box className="advisors-content-wrapper">
        <Box className="advisors-text-wrapper">
          <Typography className="advisors-title">
          Why Kiefa is for busy cultivators
          </Typography>
        </Box>
        <Box className="advisors-card-wrapper">
          <Card className="advisor-data-card" elevation={0}>
            <CardContent className="advisor-card-content">
              <Box className="advisor-comment">
                <Typography className="advisor-comment-text">
                  “Kiefa is building a dynamic software that empowers operators
                  to build out production plans tailored to their facility,
                  ensuring optimal efficiency throughout all stages of
                  production. They allow you to continuously fine tune
                  operations through actionable data and insights, which help to
                  increase your bottom line."
                </Typography>
              </Box>
              <Box className="image-title-wrapper">
              <Box className="image-wrapper">
                <Box
                  sx={{ paddingTop: "5px" }}
                  className="card-icon"
                  component="img"
                  alt="Grant Vinkavich"
                  src={grant}
                />
              </Box>
              <Box className="name-title-wrapper">
              <Typography className="advisor-name">Grant Vinkavich</Typography>
              <Typography className="advisor-title">
                Head of Cultivation @ Galaxy Labs
              </Typography>
              </Box>

              </Box>

            </CardContent>
          </Card>

          <Card className="advisor-data-card" elevation={0}>
            <CardContent className="advisor-card-content">
              <Box className="advisor-comment">
                <Typography className="advisor-comment-text">
                  “I am constantly looking for ways to create efficiency and
                  cost savings, and cultivation operations have been woefully
                  underserved. As new markets open and mature, competition and
                  price compression eat away at margin. Kiefa is creating a
                  system that will allow operators in every stratification to
                  have better visibility and control over their crop
                  management.”
                </Typography>
              </Box>
              <Box className="image-title-wrapper">
              <Box className="image-wrapper">
                <Box
                  className="card-icon"
                  component="img"
                  alt="Michael Towey"
                  src={mikey}
                />
              </Box>
              <Box className="name-title-wrapper">
              <Typography className="advisor-name">Michael Towey</Typography>
              <Typography className="advisor-title">
                VP of Cultivation @ Good Day Farm
              </Typography>
              </Box>

              </Box>

            </CardContent>
          </Card>
        </Box>
      </Box>
    </Box>
  );
}
