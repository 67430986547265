import * as React from "react";
import { Box, Typography } from "@mui/material";
import "./MemberOrganizations.css";
import cnyImage from "../../assets/CNY.png";

export default function MemberOrganizations() {
  return (
    <Box className="member-wrapper">
      <Box className="title-wrapper">
        <Typography className="member-title">Proud Member of</Typography>
      </Box>
      <Box className="member-row">
        <Box component="img" alt="Kiefa.io" src={cnyImage} />
      </Box>
    </Box>
  );
}
