import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from "./components/navbar/NavBar";
import "./App.css";
import Footer from "./components/footer/Footer";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import HomePage from "./pages/HomePage";
import Directors from "./pages/useCases/directors/Directors";
import Managers from "./pages/useCases/managers/Managers";
import Growtechs from "./pages/useCases/growTechs/GrowTechs";
import MemberOrganizations from "./components/memberOrganizations/MemberOrganizations";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const cache = createCache({
  key: "css",
  prepend: true,
});
const theme = createTheme({
  palette: {
    primary: {
      main: '#046B34',
    },
  },
});

function App() {
  return (
    <BrowserRouter>
      <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
      <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          {/* Use Case Routes */}
          <Route path="/usecase/directors" element={<Directors />} />
          <Route path="/usecase/managers" element={<Managers />} />
          <Route path="/usecase/growtechs" element={<Growtechs />} />
          {/* This will re-direct all other routes to the homepage */}
          <Route path="*" element={<HomePage />} />
        </Routes>
        <MemberOrganizations/>
        <Footer />
      </ThemeProvider>
      </CacheProvider>
    </BrowserRouter>

  );
}

export default App;
