import * as React from "react";
import { Box } from "@mui/material";
import Hero from "../../../components/hero/Hero";
import ContentWithImage from "../../../components/contentWithImage/ContentWithImage";
import LogTime from "../../../assets/growTechs/LogTime.png"
import Ipm from "../../../assets/growTechs/Ipm.png"
import Sop from "../../../assets/growTechs/Sop.png"
import LogTimeTablet from "../../../assets/growTechs/tablet/LogTimeTablet.png"
import IpmTablet from "../../../assets/growTechs/tablet/IpmTablet.png"
import SopTablet from "../../../assets/growTechs/tablet/SopTablet.png"
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Growtechs() {
    const desktop = useMediaQuery("(min-width:1416px)");
    return (
        <Box>
            <Hero title={"Log tasks, IPM issues, & metrics in one place"}
                message={"Grow technicians get to take advantage of Kiefa’s mobile browser app to track time, consumables, submit IPM issues, and more. Say goodbye to timers, spreadsheets, and whiteboards. "}
                showBookNow={false}
            />
            <ContentWithImage
                title={"Log time for each assigned task"}
                message={"Select the task and hit start – it’s as simple as that. No more stopwatches or tracking time independently. "}
                image={desktop ? LogTime : LogTimeTablet}
                imageLocation={"right"}
                showBookNow={true}
            />
            <ContentWithImage
                title={"Submit IPM issues digitally "}
                message={"Need to report an issue? Just open the app and hit send and your manager will receive it instantly, saving you the walk there or text. "}
                image={desktop ? Ipm: IpmTablet}
                imageLocation={"left"}
                showBookNow={true}
            />
            <ContentWithImage
                title={"Review SOPs from your phone "}
                message={"Get access to your facilities latest guidelines from Kiefa’s mobile web browser app. Always up-to-date, even if your director changes something."}
                image={desktop ? Sop : SopTablet}
                imageLocation={"right"}
                showBookNow={true}
            />
        </Box>
    )
}
