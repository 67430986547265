import React from "react";
import { Box, Typography, ButtonGroup, IconButton } from "@mui/material";
import "./Footer.css";
import logo from "../../assets/Kiefa-Nav-Logo.svg";
import PrivacyPolicy from "./PrivacyPolicy.pdf";
import TermsOfService from "./TermsOfService.pdf";
import X from "../../assets/Xicon.png";
import LinkedIn from "../../assets/linkedin.svg";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Footer() {
  const tablet = useMediaQuery("(max-width:1415px)");
  if (tablet) {
    return (
      <Box className="footer-content-wrapper">
        <Box className="footer-content-column" sx={{ flex: 1 }}>
          <Box
            component="img"
            sx={{
              height: 38,
              width: 100,
            }}
            alt="Kiefa.io"
            src={logo}
          />
          <Typography className="terms-message">
            © 2024 Kiefa Inc. <br /> All rights reserved.
          </Typography>
          <Typography className="policy-links" sx={{ paddingTop: "40px" }}>
            <a href={TermsOfService} rel="noopener noreferrer" target="_blank">
              <span className="policy-links">Terms of Service</span>
            </a>
          </Typography>
          <Typography className="policy-links" sx={{ marginTop: "10px" }}>
            <a href={PrivacyPolicy} rel="noopener noreferrer" target="_blank">
              <span className="policy-links">Privacy Policy</span>
            </a>
          </Typography>
        </Box>
        <Box className="footer-social-media-column">
          <ButtonGroup variant="contained" disableElevation>
            <IconButton
              className="social-media-icon"
              href="https://twitter.com/Kiefa_io"
            >
              <img src={X} alt="X" />
            </IconButton>
            <IconButton
              className="social-media-icon"
              href="https://www.linkedin.com/company/kiefa"
            >
              <img src={LinkedIn} alt="LinkedIn" />
            </IconButton>
          </ButtonGroup>
        </Box>
      </Box>
    );
  }
  return (
    <Box className="footer-content-wrapper">
      <Box className="footer-content-column" sx={{ flex: 1 }}>
        <Box
          component="img"
          sx={{
            height: 38,
            width: 100,
          }}
          alt="Kiefa.io"
          src={logo}
        />
        <Typography className="terms-message">
          © 2024 Kiefa Inc. <br /> All rights reserved.
        </Typography>
      </Box>
      <Box className="footer-social-media-column" sx={{ flex: 1 }}>
        <ButtonGroup variant="contained" disableElevation>
          <IconButton
            className="social-media-icon"
            href="https://twitter.com/Kiefa_io"
          >
            <img src={X} alt="X" />
          </IconButton>
          <IconButton
            className="social-media-icon"
            href="https://www.linkedin.com/company/kiefa"
          >
            <img src={LinkedIn} alt="LinkedIn" />
          </IconButton>
        </ButtonGroup>
      </Box>
      <Box className="footer-content-column">
        <Typography className="policy-links">
          <a href={TermsOfService} rel="noopener noreferrer" target="_blank">
            <span className="policy-links">Terms of Service</span>
          </a>
        </Typography>
        <Typography className="policy-links" sx={{ marginTop: "10px" }}>
          <a href={PrivacyPolicy} rel="noopener noreferrer" target="_blank">
            <span className="policy-links">Privacy Policy</span>
          </a>
        </Typography>
      </Box>
    </Box>
  );
}
