import * as React from "react";
import { Box, Typography } from "@mui/material";
import Hero from "../../../components/hero/Hero";
import "./Directors.css";
import ContentWithImage from "../../../components/contentWithImage/ContentWithImage";
import CreateCalendars from "../../../assets/directors/CreateCalendars.png";
import ForcastLabor from "../../../assets/directors/ForcastLabor.png";
import UpdateSop from "../../../assets/directors/UpdateSop.png";
import OrganizeSop from "../../../assets/directors/OrganizeSop.png";
import CreateCalendarsTablet from "../../../assets/directors/tablet/CreateCalendarsTablet.png";
import ForcastLaborTablet from "../../../assets/directors/tablet/ForcastLaborTablet.png";
import UpdateSopTablet from "../../../assets/directors/tablet/UpdateSopTablet.png";
import OrganizeSopTablet from "../../../assets/directors/tablet/OrganizeSopTablet.png";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Directors() {
    const desktop = useMediaQuery("(min-width:1416px)");
    return (
        <Box sx={{ width: "100%" }}>
            <Hero title={"Plan cultivation production cycles & schedule tasks faster"}
                message={"Kiefa helps cultivation directors create production plans with repeat task schedules for every room at the facility. So you can take the strategy in your head and execute it with your team."}
                showBookNow={false}
            />
            <Box className="the-problem">
                <Box className="component-row">
                    <Typography className="component-title">
                        The Problem
                    </Typography>
                </Box>
                <Box className="component-row" sx={{ paddingTop: "24px" }}>
                    <Typography className="component-sub-title">
                        It takes too much time to plan productions efficiently
                    </Typography>
                </Box>
                <Box className="component-row" sx={{ paddingTop: "56px" }}>
                    <Typography className="component-sub-text">
                        You can never plan ahead more than 90 days
                    </Typography>
                </Box>
                <Box className="component-row" sx={{ paddingTop: "24px" }}>
                    <Typography className="component-sub-text">
                        You keep having to assign repeat tasks to employees
                    </Typography>
                </Box>
                <Box className="component-row" sx={{ paddingTop: "24px" }}>
                    <Typography className="component-sub-text">
                        It’s hard to know who and what you need for your grow rooms
                    </Typography>
                </Box>
            </Box>
            <Box className="the-solution">
                <Box className="component-row">
                    <Typography className="component-title">
                        The Solution
                    </Typography>
                </Box>
                <Box className="component-row" sx={{ paddingTop: "24px" }}>
                    {desktop ? (
                        <Typography className="component-sub-title-bold">
                            Kiefa – a production management tool for cultivation directors
                        </Typography>
                    ) : (
                        <Typography className="component-sub-title">
                            <b>Kiefa</b> – a production management tool for cultivation directors
                        </Typography>
                    )}

                </Box>
            </Box>
            <ContentWithImage
                title={"Create calendars with automatic task schedules"}
                message={"In Kiefa, your production calendar and tasks are connected together. So you can schedule out grows and task workloads to employees simultaneously."}
                image={desktop ? CreateCalendars : CreateCalendarsTablet}
                imageLocation={"right"}
                showBookNow={true}
            />
            <ContentWithImage
                title={"Forecast actual labor & inventory costs for your facility"}
                message={"Kiefa lets you see actuals-based reports for labor and consumables. So you never over- or under-invest in what you need for your facility."}
                image={desktop ? ForcastLabor : ForcastLaborTablet}
                imageLocation={"left"}
                showBookNow={true}
            />
            <ContentWithImage
                title={"Globally update SOPs for all employees in one click"}
                message={"When you update an SOP in Kiefa, every team member receives the copy you just changed. No more worrying if one SOP isn’t like the other in a facility."}
                image={desktop ? UpdateSop : UpdateSopTablet}
                imageLocation={"right"}
                showBookNow={true}
            />
            <ContentWithImage
                title={"Organize SOPs in the same format, in one place "}
                message={"Kiefa encodes all your disconnected documents and PDFs into an easy-to-access digital hub for your team. Making it hassle-free for your team to adhere to your brand and compliance standards."}
                image={desktop ? OrganizeSop : OrganizeSopTablet}
                imageLocation={"left"}
                showBookNow={true}
            />
            <Box className="the-benefit">
                <Box className="component-row">
                    <Typography className="component-title">
                        The Benefit
                    </Typography>
                </Box>
                <Box className="component-row" sx={{ paddingTop: "24px" }}>
                    <Typography className="component-sub-title">
                        Set your production plan & assign tasks faster
                    </Typography>
                </Box>
                <Box className="component-row" sx={{ paddingTop: "56px" }}>
                    <Typography className="component-sub-text">
                        Build your calendar based on strategic and market needs
                    </Typography>
                </Box>
                <Box className="component-row" sx={{ paddingTop: "24px" }}>
                    <Typography className="component-sub-text">
                        Define your task schedule and replicate it
                    </Typography>
                </Box>
                <Box className="component-row" sx={{ paddingTop: "24px" }}>
                    <Typography className="component-sub-text">
                        Know what resources your facility actually needs
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}