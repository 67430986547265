import React from "react";
import logo from "../../assets/Kiefa-Nav-Logo.svg";
import mobileLogo from "../../assets/kiefa-mobile-logo.svg";
import "./NavBar.css";
import {
  IconButton,
  Box,
  AppBar,
  Toolbar,
  Button,
  Drawer,
  Typography,
  Popper,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import DownArrow from "../../assets/down.png";
import {
  Link
} from "react-router-dom";
import MenuIcon from "../../assets/menu.png";
import CloseIcon from "../../assets/close.png";
import { trackEventToGA } from "../../utils/utils";

export default function NavBar() {
  const mobile = useMediaQuery("(max-width:700px)");
  const [selectedOption, setSelectedOption] = React.useState("/");
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const drawer = (
    <Box className="drawer" sx={{ textAlign: "center" }}>
      <Box className="drawer-header">
        <Box
          className="kiefa-logo"
          component="img"
          alt="Kiefa.io"
          src={mobileLogo}
        />
        <IconButton
          color="inherit"
          aria-label="close drawer"
          edge="start"
          sx={{ stroke: "#046B34" }}
          onClick={handleDrawerToggle}
        >
          <Box
            component="img"
            alt="Kiefa.io"
            src={CloseIcon}
          />
        </IconButton>
      </Box>

      <Box className="drawer-content">
        <Box className="use-cases-title">
          Use Cases
        </Box>
        <Box className="mobile-sub-list">
          {["Directors", "Managers", "Growtechs"].map((label) => {
            return (
              <Link to={`/usecase/${label.toLocaleLowerCase()}`} key={label}>
                <Typography className="mobile-nav-item" onClick={handleDrawerToggle}>
                  {label}
                </Typography>
              </Link>

            )
          })}
        </Box>
        <Box className="mobile-blog-button-row">
          <Button
            className="book-now-text"
            sx={{ fontWeight: "600 !important", padding: "0px !important", display: "flex", justifyContent: "flex-start" }}
            href="https://blog.kiefa.io/"
            onClick={() => trackEventToGA({ category: "Button Click", action: "blog_clicked", label: `Mobile NavBar` })}
          >
            Blog
          </Button>
        </Box>
        <Box className="mobile-button-row">
          <Button
            className="book-now-text"
            sx={{ padding: "0px !important" }}
            href="https://forms.gle/BjYG5Taw9GT5bcmaA"
            onClick={() => trackEventToGA({ category: "Button Click", action: "book_demo_clicked", label: `Mobile NavBar` })}
          >
            Book Demo
          </Button>
        </Box>
        <Box className="mobile-button-row">
          <Button
            className="login-button"
            href="https://app.kiefa.io/"
            sx={{
              ":hover": {
                bgcolor: "#046B34",
                color: "white",
                cursor: "pointer",
              },
            }}
            onClick={() => trackEventToGA({ category: "Login Button Click", action: "logic_button_clicked", label: `Mobile NavBar` })}
          >
            Log in
          </Button>
        </Box>


      </Box>
    </Box>
  );

  return (
    <Box className="app-bar-container">
      <AppBar
        className="app-bar"
        position="static"
        sx={{ background: "#FEFEFE", height: "113px" }}
        elevation={0}
      >
        <Toolbar className="toolbar">
          <Box className="kiefa-logo-wrapper">
            {mobile ? (
              <Link to="/" onClick={() => setSelectedOption("/")}>
                <Box
                  className="kiefa-logo"
                  component="img"
                  alt="Kiefa.io"
                  src={mobileLogo}
                />
              </Link>

            ) : (
              <Link to="/" onClick={() => setSelectedOption("/")}>
                <Box
                  className="kiefa-logo"
                  component="img"
                  alt="Kiefa.io"
                  src={logo}
                />
              </Link>

            )}
          </Box>
          <Box sx={{ flex: 1 }} />
          {mobile ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              sx={{ stroke: "#046B34" }}
              onClick={handleDrawerToggle}
            >
              <Box
                component="img"
                alt="Kiefa.io"
                src={MenuIcon}
              />
            </IconButton>
          ) : (
            <>
              <Box className="use-cases"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <Typography className="use-cases-button">
                  Use Cases
                </Typography>
                <Box
                  className="down-chevron"
                  component="img"
                  src={DownArrow}
                  sx={{ transform: open ? "rotate(180deg)" : "" }}
                />
                <Popper
                  open={open}
                  anchorEl={anchorEl}
                  placement="bottom"
                  sx={{ pt: 1 }}
                >
                  <Box
                    className="sub-menu-list"
                    sx={{ bgcolor: 'background.paper' }}>
                    {["Directors", "Managers", "Growtechs"].map((label) => {
                      return (
                        <Link to={`/usecase/${label.toLocaleLowerCase()}`} key={label} onClick={() => setSelectedOption(label)}>
                          <Typography className="nav-item" sx={{ color: selectedOption === label ? "#046B34 !important" : "#020202 !important", fontWeight: selectedOption === label ? "600 !important" : "400 !important" }}>
                            {label}
                          </Typography>
                        </Link>
                      )
                    })}
                  </Box>
                </Popper>
              </Box>

              <Button
                className="blog-button"
                sx={{ width: "auto !important" }}
                href="https://blog.kiefa.io/"
                onClick={() => trackEventToGA({ category: "Button Click", action: "blog_clicked", label: `NavBar` })}
              >
                Blog
              </Button>

              <Button
                className="contact-us-button"
                sx={{ marginRight: "14px", width: "auto !important" }}
                href="https://forms.gle/BjYG5Taw9GT5bcmaA"
                onClick={() => trackEventToGA({ category: "Button Click", action: "book_demo_clicked", label: `NavBar` })}
              >
                Book Demo
              </Button>

              <Button
                className="login-button"
                href="https://app.kiefa.io/"
                sx={{
                  ":hover": {
                    bgcolor: "#046B34",
                    color: "white",
                    cursor: "pointer",
                  },
                }}
                onClick={() => trackEventToGA({ category: "Login Button Click", action: "logic_button_clicked", label: `NavBar` })}
              >
                Log in
              </Button>
            </>
          )}
        </Toolbar>

        <Box component="nav">
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: "100%",
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </AppBar>
    </Box>
  );
}
