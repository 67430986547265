import React from "react";
import { Box } from "@mui/material";
import "./LargeImage.css";

interface LargeImageProps {
    image: string;
}

export default function LargeImage({ image }: LargeImageProps) {
    return (
        <Box className="large-image-wrapper">
            <Box
                className="large-image"
                component="img"
                alt="Kiefa.io"
                src={image}
            />
        </Box>
    )
}