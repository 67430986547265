import * as React from "react";
import { Box } from "@mui/material";
import Hero from "../../../components/hero/Hero";
import ContentWithImage from "../../../components/contentWithImage/ContentWithImage";
import Consumables from "../../../assets/managers/Consumables.png"
import Ipm from "../../../assets/managers/Ipm.png"
import MonitorEmployees from "../../../assets/managers/MonitorEmployees.png"
import ScheduleTasks from "../../../assets/managers/ScheduleTasks.png"
import ConsumablesTablet from "../../../assets/managers/tablet/ConsumablesTablet.png"
import IpmTablet from "../../../assets/managers/tablet/IpmTablet.png"
import MonitorEmployeesTablet from "../../../assets/managers/tablet/MonitorEmployeesTablet.png"
import ScheduleTasksTablet from "../../../assets/managers/tablet/ScheduleTasksTablet.png"
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Managers() {
    const desktop = useMediaQuery("(min-width:1416px)");
    return (
        <Box>
            <Hero title={"Track facility staff & IPM issues digitally"}
                message={"Kiefa lets managers track task attainment, IPM issues, and share reports to leadership – all within one platform. No need for jumbled spreadsheets or whiteboards anymore."}
                showBookNow={false}
            />
            <ContentWithImage
                title={"Schedule tasks to employees"}
                message={"Create task schedules or assign one-offs to grow technicians based on what your rooms need – without having to go back and forth to your whiteboard daily."}
                image={desktop ? ScheduleTasks : ScheduleTasksTablet}
                imageLocation={"right"}
                showBookNow={true}
            />
            <ContentWithImage
                title={"Monitor employee task completion & attainment"}
                message={"Don’t worry about going to the room with your grow tech. See employee task completions and attainment percentages in Kiefa."}
                image={desktop ? MonitorEmployees : MonitorEmployeesTablet}
                imageLocation={"left"}
                showBookNow={true}
            />
            <ContentWithImage
                title={"Track IPM issues digitally"}
                message={"When was the last time an IPM issue went unreported? Kiefa notifies you immediately when a tech submits an IPM issue. So you can take action faster. "}
                image={desktop ? Ipm : IpmTablet}
                imageLocation={"right"}
                showBookNow={true}
            />
            <ContentWithImage
                title={"Track consumables usage way in advance of shortage"}
                message={"No last minute trips to Walmart. Kiefa lets you track consumables through your production cycle and before you start a new grow."}
                image={desktop ? Consumables : ConsumablesTablet}
                imageLocation={"left"}
                showBookNow={true}
            />
        </Box>
    )
}
