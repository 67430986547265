import * as React from "react";
import { Box } from "@mui/material";
import Hero from "../components/hero/Hero";
import LargeImage from "../components/largeImage/LargeImage";
import HeroImage from "../assets/homePage/Hero.png";
import HeroImageTablet from "../assets/homePage/tablet/HeroTablet.png";
import Clients from "../components/clients/Clients";
import ContentWithImage from "../components/contentWithImage/ContentWithImage";
import CreateCalendars from "../assets/homePage/CreateCalendars.png";
import HarvestSchedule from "../assets/homePage/UnderstandYourHarvest.png";
import AssignTasks from "../assets/homePage/AssignTasks.png";
import UpdateSop from "../assets/homePage/UpdateSop.png";
import Workflows from "../components/workflows/Workflows";
import Advisors from "../components/advisors/Advisors";
import TrackAndTrace from "../assets/homePage/TrackAndTrace.png";
import TrackAndTraceTablet from "../assets/homePage/tablet/TrackAndTraceTablet.png";
import CreateCalendarsTablet from "../assets/homePage/tablet/CreateCalendarsTablet.png";
import HarvestScheduleTablet from "../assets/homePage/tablet/UnderstandYourHarvestTablet.png";
import AssignTasksTablet from "../assets/homePage/tablet/AssignTasksTablet.png";
import UpdateSopTablet from "../assets/homePage/tablet/UpdateSopTablet.png";
import CreateCalendarsMobile from "../assets/homePage/mobile/CreateCalendarsMobile.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import Integrations from "../components/integrations/Integrations";

export default function HomePage() {
    const mobile = useMediaQuery("(max-width:768px)");
    const desktop = useMediaQuery("(min-width:1416px)");
    return (
        <Box>
            <Hero title={"Cultivation software made for profitability"}
                message={"Kiefa helps cultivators track production cycles, automate task management, and forecast yields – without having to spend hours in spreadsheets."}
                showBookNow={true}
            />
            {!mobile ? (
                <LargeImage
                    image={desktop ? HeroImage : HeroImageTablet}
                />
            ) : null}

            <Clients />
            {mobile ? (
                <Box className="content-image-wrapper">
                    <Box className="content-image" component="img" alt="Kiefa.io" src={CreateCalendarsMobile} />
                </Box>
            ) : null}
            <ContentWithImage
                title={"Create calendars & task schedules for production plans"}
                message={"Your production cycle is the most important part of your operation. Create automatic task schedules and calendars in Kiefa – no more whiteboards, spreadsheets, and documents."}
                image={desktop ? CreateCalendars : CreateCalendarsTablet}
                imageLocation={"right"}
                showBookNow={true}
            />
            <ContentWithImage
                title={"Understand your harvest schedule"}
                message={"Get an automatic report showing your harvest schedule and how much of each strain you expect to yield. Finally, you can operate more proactively. "}
                image={desktop ? HarvestSchedule : HarvestScheduleTablet}
                imageLocation={"left"}
                showBookNow={true}
            />
            <ContentWithImage
                title={"Assign tasks to grow techs & track completion times against your estimates"}
                message={"Create repeatable task schedules with labor estimates that you can use to measure employee efficiency. Say goodbye to stopwatches and paper logs."}
                image={desktop ? AssignTasks : AssignTasksTablet}
                imageLocation={"right"}
                showBookNow={true}
            />
            <ContentWithImage
                title={"Update SOPs for all employees, from one place"}
                message={"Change your master SOPs inside Kiefa and every team member can see your change online, so your grow operation will always meet your brand standard."}
                image={desktop ? UpdateSop : UpdateSopTablet}
                imageLocation={"left"}
                showBookNow={true}
            />
            <ContentWithImage
                title={"Let your track and trace data thrive"}
                message={"Kiefa will automatically ingest harvest data and strain metrics."}
                messages={[
                    {
                        title: "Make sense of harvest data",
                        message: "Kiefa enables you to see how estimated yields stack up against actuals. You can evaluate trends over time and optimize your future crop planning."
                    },
                    {
                        title: "One strain, many licenses",
                        message: "Have a single strain spread across multiple licenses? Kiefa allows you to link them under one unified view to evaluate performance."
                    }
                ]}
                image={desktop ? TrackAndTrace : TrackAndTraceTablet}
                imageLocation={"right"}
                floatImage={true}
                showBookNow={true}
            />
            {!mobile ? (
            <Workflows />
            ) : null}
            <Advisors />
            <Integrations/>
        </Box>
    )
}